//Colors
$primary-color: #335179;
$secondary-color: #94BBC7;
$title-color-light: #FFFFFF;

// Sizes
$header-nav-height: 64px;
$sidebar-left-width: 250px;
$margin-layout: 50px;
$sidebar-feedback-right-width: 300px;
$sidebar-order-right-width: 300px;

#root {
	width: 100%;
	height: 100%;
	overflow: auto;
}

::-webkit-scrollbar{
	width: 2.5px!important;
}

h2.ui.header {
	color: $primary-color
}

.ui.container {
	margin: 2rem
}

.shadow-white-text {
	text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000;
}

.ui.button {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.ui.form input[type=tel] {
	padding-left: 50px;
}

.display-block-child > div   {
	display: block!important;
}

input[type=file] {
	display: none;
}

.label-custom {
	margin: 0.5rem 0 0.5rem 0 !important;
}

.ui.form .field>label {
	backgrund-color: #fff;
}

.ui.modal .actions>.button {
	margin-left: 0;
}